import { TableCell, TableRow, Tooltip, Typography } from '@mui/material';

import {
  MealItemCustomAddonResponse,
  MealPhotoQueueResponse,
  MealPushQuestionStatusEnum,
  UsdaNutritionResponse,
} from 'api/generated/MNT';
import type { MealItem } from 'apiClients/mpq';
import { FilterSortTable } from 'components/FilterSortTable';
import ScrollX from 'components/ScrollX';
import type { DraftItem } from 'types/DraftItem';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'App.css';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFoodDetailsPopup } from 'components/FoodDetailsPopup';
import MainCard from 'components/MainCard';
import { MealItemNutrientValue } from 'components/MealItemNutrientValue';
import { useFeatures } from 'context/FeatureContext';
import { formatNumber } from 'food-editor/utils/utils';
import _ from 'lodash';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useFoodDetails } from 'services/FoodDetailsService';
import { mealItemGetNutrientValue, NUTRIENT_VALUE_LOADING, NutrientDef, nutrientGetDef } from 'utils/mealItems';
import { coalesceNaN } from 'utils/numerical';
import {
  getEmptyMealItem,
  RelevantNutrients,
  usePushQuestions,
  useRelevantNutrients,
} from '../services/QueueItemEditorService';
import { formatMealItemSizing } from './MealBuilder';
import { getMealPushQuestionColor } from './MealPushQuestions';
import { PatientContext } from './usePatientContext';

export const getFoodEditorUrl = (foodName: string) => {
  return `/foods/${encodeURIComponent(foodName)}`;
};

export const checkPctEaten = (percentEaten: number | undefined) => {
  return coalesceNaN(percentEaten, 1);
};

export const getItemWeightWithoutAddons = (
  item: { serving_unit_amount: number, servings: number },
  percentEaten?: number | undefined,
) => {
  return item.serving_unit_amount * item.servings * checkPctEaten(percentEaten);
};

export const getItemWeightTotal = (item: MealItem) => {
  let addonWeight = 0;
  item.custom_addons?.forEach(addon => {
    addonWeight += getItemWeightWithoutAddons(addon, item.percent_eaten);
  });
  return addonWeight + getItemWeightWithoutAddons(item, item.percent_eaten);
};

export const getMealTotalWeight = (draftItems: DraftItem[]) => {
  return _.sum(draftItems.map(item => getItemWeightTotal(item.item)));
};

export const useMealNutrientValues = (opts: {
  draftItems: DraftItem[],
}) => {
  const allFoodNames = useMemo(() => {
    const allFoodNames = [] as string[];
    opts.draftItems.forEach(v => {
      allFoodNames.push(v.item.custom_item && v.searchItem ? v.searchItem.name : v.item.food_name);
      v.item.custom_addons?.forEach(addon => {
        allFoodNames.push(addon.food_name);
      });
    });
    return allFoodNames;
  }, [opts.draftItems]);
  const foodDetails = useFoodDetails(allFoodNames);

  const getItemNutrientWithoutAddons = (
    item: MealItem | MealItemCustomAddonResponse,
    nutrient: keyof UsdaNutritionResponse,
  ) => {
    const getFoodName = () => {
      if (!('custom_item' in item) || !item.custom_item) {
        return item.food_name;
      }

      const draftItem = opts.draftItems.find(v => v.item.food_name == item.food_name);
      return draftItem?.searchItem?.name || item.food_name;
    };

    const details = foodDetails[getFoodName()];
    if (!details || details.query.isLoading) {
      return NUTRIENT_VALUE_LOADING;
    }

    return mealItemGetNutrientValue({
      item,
      foodNutrients: details?.usda_nutrition ?? null,
      nutrient,
    });
  };

  const getItemNutrientTotal = (item: MealItem, nutrient: keyof UsdaNutritionResponse) => {
    let addonCarbs = 0;
    const percentEaten = checkPctEaten(item.percent_eaten);
    item.custom_addons?.forEach(addon => {
      // in mealItemGetnutrientValue, addon's %-eaten is always defaulted to 1, but item's %-eaten is calculated normally
      // so here only apply %-eaten to addon calculations
      addonCarbs += coalesceNaN(getItemNutrientWithoutAddons(addon, nutrient)?.value * percentEaten, 0);
    });
    return addonCarbs + coalesceNaN(getItemNutrientWithoutAddons(item, nutrient)?.value, 0);
  };

  const getMealTotal = (nutrient: keyof UsdaNutritionResponse) => {
    return _.sum(opts.draftItems.map(v => getItemNutrientTotal(v.item, nutrient)));
  };

  return {
    getItemNutrientWithoutAddons,
    getItemNutrientTotal,
    getMealTotal,
  };
};

export const NUTRIENT_WARNING_THRESHOLDS = {
  fibre_g: 5,
  carbohydrates_g: 100,
  netcarb_g: 100,
  energy_kcal: 1000,
  weight_g: 500,
} as const;

export const NUTRIENT_WARNING_WEIGHT_1G = 1;

const NutrientValueWithWarning = (props: {
  nutrientName: string,
  value: number,
  valueStr: string,
}) => {
  const threshold = (NUTRIENT_WARNING_THRESHOLDS as any)[props.nutrientName];
  const isOverWeight = !!threshold && (props.value || 0) >= threshold;
  const isEq1 = props.nutrientName == 'weight_g' && props.value == NUTRIENT_WARNING_WEIGHT_1G;
  if (!(isOverWeight || isEq1)) {
    return <span>{props.valueStr}</span>;
  }

  const errMsg = isOverWeight
    ? `${props.nutrientName} greater than ${formatNumber(threshold)}`
    : `${props.nutrientName} = 1g`;

  return (
    <Tooltip
      title={errMsg}
    >
      <span style={{ whiteSpace: 'nowrap', fontWeight: 'bold', color: 'red' }}>
        {props.valueStr} ⚠️
      </span>
    </Tooltip>
  );
};

export const SummaryTable = (props: {
  foodDraftItems: DraftItem[],
  beverageDraftItems: DraftItem[],
  isForConfirmationDialog?: boolean,
  relevantNutrients: RelevantNutrients,
  pushQuestions?: ReturnType<typeof usePushQuestions>,
  disableSizingColWrap?: boolean,
  smallerText?: boolean,
}) => {
  const { relevantNutrients, pushQuestions } = props;
  const { getItemNutrientWithoutAddons, getItemNutrientTotal } = useMealNutrientValues({
    draftItems: [...props.foodDraftItems, ...props.beverageDraftItems],
  });
  const featureFlags = useFeatures();
  // const [foodCustomTip, setFoodCustomTip] = useState('');
  // const [beverageCustomTip, setBeverageCustomTip] = useState('');

  const getMealPushQuestion = (item: MealItem) => {
    if (!pushQuestions) {
      return undefined;
    }

    return (
      [...props.foodDraftItems, ...props.beverageDraftItems].find(d => d.id == item.id)?.pushQuestionUpdate
        ?? pushQuestions.mealQuestions.find(q => q.meal_item_id == item.id)
    );
  };

  const totalFoodWeight = getMealTotalWeight(props.foodDraftItems);
  const totalBeverageWeight = getMealTotalWeight(props.beverageDraftItems);

  const fmtItemWeight = (
    item: {
      serving_unit_amount: number,
      servings: number,
      percent_eaten?: number | null,
      food_ontology?: string[] | null,
    },
    parent: MealItem | null,
  ) => {
    const pctEaten = item.percent_eaten ?? parent?.percent_eaten ?? 1;
    const foodOntology = item.food_ontology ?? parent?.food_ontology ?? [];
    const weight = getItemWeightWithoutAddons(item, checkPctEaten(pctEaten));
    const foodOrBeverage = foodOntology[0] == 'beverage' ? 'beverage' : 'food';
    const totalWeight = foodOrBeverage == 'food' ? totalFoodWeight : totalBeverageWeight;
    return (
      <>
        <NutrientValueWithWarning
          nutrientName="weight_g"
          value={weight}
          valueStr={formatNumber(weight, 0) + 'g'}
        />
        {totalWeight > 0 && <span>&nbsp;({formatNumber(weight * 100 / totalWeight, 0)}%)</span>}
      </>
    );
  };

  const summaryRowFontStyle = {
    fontWeight: props.isForConfirmationDialog ? 'bold' : 'normal',
    fontSize: props.smallerText ? '0.8rem' : undefined,
  };
  const addOnFrontStyle = {
    fontSize: props.smallerText ? '0.7rem' : '0.75rem',
  };

  const mkMealSummaryCell = (
    render: (item: MealItem | MealItemCustomAddonResponse, parent: MealItem | null) => string | JSX.Element,
    showToolTip?: boolean,
  ) => {
    return ({ row }: any) => {
      const { item, parent } = row?.original || {};
      if (!item) {
        return null;
      }
      const toolTip = showToolTip && item.custom_tip ? `Custom tip: ${item.custom_tip}` : '';
      return (
        <Tooltip title={toolTip}>
          <Typography
            style={{
              fontSize: row?.original?.type == 'addon' ? addOnFrontStyle.fontSize : summaryRowFontStyle.fontSize,
            }}
          >
            {render(item, parent)}
          </Typography>
        </Tooltip>
      );
    };
  };

  const columns = [
    {
      Header: 'Name',
      Cell: mkMealSummaryCell((_item, parent) => {
        const item = _item as MealItem;
        if (!item.food_name) {
          return '';
        }

        const pushQuestion = getMealPushQuestion(item);
        const labelFlag = {
          [MealPushQuestionStatusEnum.Pendingsystem]: 'Question with answer',
          [MealPushQuestionStatusEnum.Pendingpatient]: 'Question awaiting patient response',
          [MealPushQuestionStatusEnum.Resolved]: 'Question resolved',
        };
        const flags = [
          item.custom_item && { short: 'C', name: 'Custom item', color: 'orange' },
          item.last_updated_by_user_id && item.last_updated_by_user_id == item.patient_id && {
            short: 'E',
            name: 'Edited last by patient',
            color: 'blue',
          },
          item.id && !!pushQuestion
          && {
            short: 'Q',
            name: (labelFlag as any)[pushQuestion.question_status as any] || 'Has question',
            color: getMealPushQuestionColor(pushQuestion),
          },
          item.custom_tip && {
            short: 'T',
            name: 'Custom tip: ' + item.custom_tip,
            color: 'blue',
          },
        ].filter(Boolean) as { short: string, name: string, color: string }[];

        return (
          <>
            <span {...foodDetailsPopup.getShowOnHoverProps({ mealItem: item })} style={{ cursor: 'pointer' }}>
              {!!parent && '↳'}
              {item.food_name_alias ?? item.food_name}
            </span>
            {flags.map(flag => (
              <span
                key={flag.short}
                style={{
                  color: flag.color,
                  marginLeft: 2,
                  cursor: 'default',
                }}
                title={flag.name}
              >
                {flag.short}
              </span>
            ))}
            &nbsp;
            <Link
              style={{ cursor: 'pointer' }}
              to={getFoodEditorUrl(item.food_name)}
              target="_blank"
            >
              <FontAwesomeIcon
                icon={faExternalLinkAlt}
                size="sm"
                color="black"
              />
            </Link>
            {item.food_name_alias && (
              <>
                <br />
                <Typography variant="caption" sx={{ color: 'gray' }}>
                  "{item.food_name}"
                </Typography>
              </>
            )}
          </>
        );
      }, true),
    },

    {
      Header: 'Sizing',
      Cell: mkMealSummaryCell((_item, parent) => {
        if (!_item.food_name) {
          return '';
        }
        return formatMealItemSizing(_item, parent, props.disableSizingColWrap ? 'normal' : 'nowrap');
      }),
    },

    {
      Header: 'Weight',
      Cell: mkMealSummaryCell((item, parent) => {
        if (!item.food_name) {
          return '';
        }
        return fmtItemWeight(item, parent);
      }),
    },

    ...relevantNutrients.map(n => ({
      Header: n.label,
      Cell: mkMealSummaryCell((item, parent) => {
        if (!item.food_name) {
          return '';
        }

        const pctEaten = (item as MealItem).percent_eaten ?? parent?.percent_eaten ?? 1;
        const res = getItemNutrientWithoutAddons(item, n.nutrient);

        if (parent) {
          // Item is an add-on
          res.value = res.value * checkPctEaten(pctEaten);
          res.valueStr = res.def.format(res.value);
        }

        return <MealItemNutrientValue value={res} />;
      }),
    })),
  ];

  const getTableData = () => {
    const convertDraftItemToTableData = (draftItems: DraftItem[]) => {
      return draftItems.reduce((acc, v) => {
        return [
          ...acc,
          { type: 'item' as const, item: { ...v.item, id: v.id || undefined } },
          ...v.item.custom_addons?.map(addon => ({ type: 'addon' as const, item: addon, parent: v.item })) ?? [],
        ];
      }, [] as Array<{ type: 'item' | 'addon', item: MealItem | MealItemCustomAddonResponse }>);
    };

    const beverageData = convertDraftItemToTableData([...props.beverageDraftItems].reverse());
    const foodData = convertDraftItemToTableData([...props.foodDraftItems].reverse());
    const emptyRow = beverageData.length > 0 && foodData.length > 0 ? [{ type: 'item', item: getEmptyMealItem() }] : [];

    return [...foodData, ...emptyRow, ...beverageData];
  };

  const tableData = getTableData();
  const foodDetailsPopup = useFoodDetailsPopup();

  // const handleMouseEnter = (row: any) => {
  //   if (!row.item.custom_tip) {
  //     setFoodCustomTip('');
  //     setBeverageCustomTip('');
  //     return;
  //   }

  //   if (row.item.food_ontology?.[0] == 'beverage') {
  //     setBeverageCustomTip('CT: ' + row.item.custom_tip);
  //     return;
  //   }

  //   setFoodCustomTip('CT: ' + row.item.custom_tip);
  // };

  // const handleMouseLeave = () => {
  //   setFoodCustomTip('');
  //   setBeverageCustomTip('');
  // };

  return (
    // <MainCard content={false} sx={{ position: 'sticky', top: '4.5rem', zIndex: '100' }}>
    <MainCard content={false}>
      <ScrollX>
        <FilterSortTable
          columns={columns}
          data={tableData}
          striped={true}
          getCellProps={(cell: any, curProps: any) => {
            const rowVal = cell.row.original;
            if (!rowVal) {
              return curProps;
            }
            return {
              ...curProps,
              style: {
                ...(curProps.style || {}),
                ...(rowVal.type == 'addon'
                  ? {
                    paddingTop: 5,
                    paddingBottom: 5,
                  }
                  : {}),
                ...(rowVal.type == 'item' && rowVal.item.custom_addons?.length
                  ? {
                    paddingBottom: 5,
                  }
                  : {}),
              },
            };
          }}
          rowEnterHandler={() => {}}
          rowLeaveHandler={() => {}}
          summaryRow={
            <>
              <TableRow>
                <TableCell sx={{ fontSize: summaryRowFontStyle.fontSize }}>
                  {/* {foodCustomTip} */}
                </TableCell>
                <TableCell
                  sx={{ ...summaryRowFontStyle, textAlign: 'right' }}
                  colSpan={columns.length - relevantNutrients.length - 2}
                >
                  Food Total
                </TableCell>
                <TableCell sx={summaryRowFontStyle}>
                  <NutrientValueWithWarning
                    nutrientName="weight_g"
                    value={totalFoodWeight}
                    valueStr={formatNumber(totalFoodWeight, 0) + 'g'}
                  />
                </TableCell>
                {relevantNutrients.map(n => {
                  const [def, totalVal] = props.foodDraftItems.reduce((acc, v) => {
                    return [
                      nutrientGetDef(n.nutrient),
                      acc[1] + getItemNutrientTotal(v.item, n.nutrient),
                    ];
                  }, [null as NutrientDef | null, 0]);

                  return (
                    <TableCell key={n.label} sx={summaryRowFontStyle}>
                      <NutrientValueWithWarning
                        nutrientName={def?.nutrient ?? ''}
                        value={totalVal}
                        valueStr={def?.format(totalVal) ?? '–'}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontSize: summaryRowFontStyle.fontSize }}>
                  {/* {beverageCustomTip} */}
                </TableCell>
                <TableCell
                  sx={{ ...summaryRowFontStyle, textAlign: 'right' }}
                  colSpan={columns.length - relevantNutrients.length - 2}
                >
                  Beverage Total
                </TableCell>
                <TableCell sx={summaryRowFontStyle}>
                  <NutrientValueWithWarning
                    nutrientName="weight_g"
                    value={totalBeverageWeight}
                    valueStr={formatNumber(totalBeverageWeight, 0) + 'g'}
                  />
                </TableCell>
                {relevantNutrients.map(n => {
                  const [def, totalVal] = props.beverageDraftItems.reduce((acc, v) => {
                    return [
                      nutrientGetDef(n.nutrient),
                      acc[1] + getItemNutrientTotal(v.item, n.nutrient),
                    ];
                  }, [null as NutrientDef | null, 0]);

                  return (
                    <TableCell key={n.label} sx={summaryRowFontStyle}>
                      <NutrientValueWithWarning
                        nutrientName={def?.nutrient ?? ''}
                        value={totalVal}
                        valueStr={def?.format(totalVal) ?? '–'}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            </>
          }
          numRows={100}
        />
      </ScrollX>
    </MainCard>
  );
};

export const MealSummary = (props: {
  patientContext: PatientContext,
  queue: MealPhotoQueueResponse,
  draftItems: DraftItem[],
  isForConfirmationDialog?: boolean,
}) => {
  const { queue } = props;

  const pushQuestions = usePushQuestions({
    patientId: queue.patient_id,
    mealId: queue.created_meal_id,
  });

  const relevantNutrients = useRelevantNutrients({
    context: 'summary',
    patientContext: props.patientContext,
  });

  return (
    <SummaryTable
      foodDraftItems={props.draftItems.filter(i =>
        !i.item.food_ontology
        || (!!i.item.food_ontology && i.item.food_ontology[0] != 'beverage')
      )}
      beverageDraftItems={props.draftItems.filter(i => !!i.item.food_ontology && i.item.food_ontology[0] == 'beverage')}
      isForConfirmationDialog={props.isForConfirmationDialog}
      relevantNutrients={relevantNutrients}
      pushQuestions={pushQuestions}
    />
  );
};
