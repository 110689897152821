import { FoodDetailsResponse, FoodVTO } from 'api/generated/MNT';
import { FoodEditorNutrientValue } from './food-editor-nutrient-fields';
import { FoodEditorValue } from './types';

export type FoodDerivation = {
  fieldVisible: boolean,
  needsReview: boolean,
  value: string | number | boolean,
  location: FoodDetailsFieldLocation,
};

export type FoodDetailsFieldLocation =
  | { location: 'cc', field: keyof NonNullable<FoodDetailsResponse['cc']> }
  | { location: 'food', field: keyof NonNullable<FoodDetailsResponse['food']> }
  | { location: 'ranking', field: keyof NonNullable<FoodDetailsResponse['ranking']> }
  | { location: 'usda_nutrition', field: keyof NonNullable<FoodDetailsResponse['usda_nutrition']> };

export type FoodDerivationDependencyInputs = {
  'usda_nutrition.potassium_mg': FoodEditorNutrientValue,
  'food.k_level': string,
  [key: string]: any,
};

type FoodDerivationDefinition = {
  dependsOn: FoodDetailsFieldLocation[],
  calculate: (inputs: FoodDerivationDependencyInputs) => FoodDerivation,
};

export type FoodDerivationFieldDefinition = {
  name: string,
  derivation: FoodDerivationDefinition,
};

const FOOD_EDITOR_CC_RANKING_DERIVATION_DEFINITIONS: FoodDerivationFieldDefinition[] = [
  {
    name: 'food.k_level',
    derivation: {
      dependsOn: [
        { location: 'usda_nutrition', field: 'potassium_mg' },
        {
          location: 'food',
          field: 'suggested_serving_amount_g',
        },
        { location: 'food', field: 'adult_serving_size_g' },
      ],
      calculate: (inputs) => {
        const potassiumMg = inputs['usda_nutrition.potassium_mg'] || 0;
        const servingAmountG = inputs['food.suggested_serving_amount_g'] || inputs['food.adult_serving_size_g'] || 0;
        const calculatedPotassiumMg = potassiumMg * servingAmountG / 100;
        let potassiumLevel = '';
        if (calculatedPotassiumMg > 0) {
          potassiumLevel = calculatedPotassiumMg >= 200 ? 'high' : calculatedPotassiumMg < 100 ? 'low' : 'medium';
        }
        return {
          fieldVisible: !!potassiumLevel,
          needsReview: false,
          value: potassiumLevel,
          location: { location: 'food', field: 'k_level' },
        };
      },
    },
  },
  {
    name: 'ranking.low_k',
    derivation: {
      dependsOn: [
        { location: 'food', field: 'k_level' },
        { location: 'usda_nutrition', field: 'potassium_mg' },
        {
          location: 'food',
          field: 'suggested_serving_amount_g',
        },
        { location: 'food', field: 'adult_serving_size_g' },
      ],
      calculate: (inputs) => {
        const kLevel = inputs['food.k_level'];
        const potassiumMg = inputs['usda_nutrition.potassium_mg'] || 0;
        const servingAmountG = inputs['food.suggested_serving_amount_g'] || inputs['food.adult_serving_size_g'] || 0;
        const calculatedPotassiumMg = potassiumMg * servingAmountG / 100;
        let ranking = '0';
        if (kLevel === 'low') {
          ranking = calculatedPotassiumMg < 20 ? '1' : calculatedPotassiumMg >= 60 ? '3' : '2';
        }
        return {
          fieldVisible: kLevel === 'low',
          needsReview: false,
          value: ranking,
          location: { location: 'ranking', field: 'low_k' },
        };
      },
    },
  },
  {
    name: 'ranking.high_k',
    derivation: {
      dependsOn: [
        { location: 'food', field: 'k_level' },
        { location: 'usda_nutrition', field: 'potassium_mg' },
        {
          location: 'food',
          field: 'suggested_serving_amount_g',
        },
        { location: 'food', field: 'adult_serving_size_g' },
      ],
      calculate: (inputs) => {
        const kLevel = inputs['food.k_level'];
        const potassiumMg = inputs['usda_nutrition.potassium_mg'] || 0;
        const servingAmountG = inputs['food.suggested_serving_amount_g'] || inputs['food.adult_serving_size_g'] || 0;
        const calculatedPotassiumMg = potassiumMg * servingAmountG / 100;
        let ranking = '0';
        if (kLevel === 'high') {
          ranking = calculatedPotassiumMg >= 450 ? '1' : calculatedPotassiumMg < 300 ? '3' : '2';
        }
        return {
          fieldVisible: kLevel === 'high',
          needsReview: false,
          value: ranking,
          location: { location: 'ranking', field: 'high_k' },
        };
      },
    },
  },
];

const FOOD_DETAILS_TO_FOOD_EDITOR_VALUE_MAPPING: { [key: string]: keyof FoodEditorValue } = {
  'k_level': 'potassiumLevel',
  'low_k': 'rankingLowK',
  'high_k': 'rankingHighK',
} as const;

export const foodDetailsToDerivations = (foodDetails: Partial<FoodDetailsResponse>): FoodDerivation[] => {
  const cachedDerivationValues: { [key: string]: any } = {};
  const derivations = FOOD_EDITOR_CC_RANKING_DERIVATION_DEFINITIONS.map(def => {
    const inputs = def.derivation.dependsOn.reduce<FoodDerivationDependencyInputs>((inputAcc, inputFieldName) => {
      const { location, field } = inputFieldName;
      const cachedKey = `${location}.${field}`;
      if (!cachedDerivationValues[cachedKey]) {
        const value = (foodDetails as unknown as any)[location]?.[field] ?? null;
        cachedDerivationValues[cachedKey] = value;
      }
      inputAcc[cachedKey] = cachedDerivationValues[cachedKey];
      return inputAcc;
    }, {} as FoodDerivationDependencyInputs);

    const derivation = def.derivation.calculate(inputs);
    cachedDerivationValues[def.name] = derivation.value;
    return derivation;
  });
  return derivations;
};

export const applyDerivationsToFoodDetails = (
  foodDetails: FoodDetailsResponse,
  derivations: FoodDerivation[],
): FoodDetailsResponse => {
  const updatedFoodDetails = { ...foodDetails };

  derivations.forEach((derivation) => {
    const { location, field } = derivation.location;
    // TODO: what to do if derived value is different from current value (entered by user)?
    if (location === 'food') {
      updatedFoodDetails.food = { ...updatedFoodDetails.food!, [field]: derivation.value };
    } else if (location === 'ranking') {
      updatedFoodDetails.ranking = {
        ...updatedFoodDetails.ranking!,
        [field]: FOOD_DETAILS_TO_FOOD_EDITOR_VALUE_MAPPING,
      };
    } else {
      updatedFoodDetails.cc = { ...updatedFoodDetails.cc, [field]: derivation.value };
    }
  });

  return updatedFoodDetails;
};

export const applyDerivationsToFoodEditorValue = (
  foodEditorValue: FoodEditorValue,
  derivations: FoodDerivation[],
): FoodEditorValue => {
  const partial: Partial<FoodEditorValue> = {};
  derivations.forEach((derivation) => {
    const { location, field } = derivation.location;
    // TODO: what to do if derived value is different from current value (entered by user)?
    const foodEditorValueFieldName = FOOD_DETAILS_TO_FOOD_EDITOR_VALUE_MAPPING[field];
    (partial as Record<string, unknown>)[foodEditorValueFieldName] = derivation.value;
  });
  return { ...foodEditorValue, ...partial };
};
