import {
  getItemWeightWithoutAddons,
  getMealTotalWeight,
  MealSummary,
  NUTRIENT_WARNING_THRESHOLDS,
  NUTRIENT_WARNING_WEIGHT_1G,
  useMealNutrientValues,
} from 'pages/QueueItem/meal-builder/MealSummary';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CottageIcon from '@mui/icons-material/Cottage';
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import IconButton from 'components/@extended/IconButton';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import { PatientAgeValue, PatientDiet } from 'pages/QueueItem/meal-builder/PatientAge';

import { type MealQueueItem, onSendHelpCallback, sendHelpRequest } from 'apiClients/mpq';
import { useAuth } from 'context/appContext';
import { useFeatures } from 'context/FeatureContext';

import { LeftOutlined } from '@ant-design/icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import MainCard from 'components/MainCard';
import { formatNumber } from 'food-editor/utils/utils';
import { draftItemGetWarnings, MealBuilderActions, QueueMealItems } from 'pages/QueueItem/meal-builder/MealBuilder';
import { MealPhoto } from 'pages/QueueItem/meal-builder/MealPhoto';
import { PatientContext, usePatientContext } from 'pages/QueueItem/meal-builder/usePatientContext';
import {
  getEmptyMealItem,
  QueueItemEditorStateCtx,
  SubmitMealResult,
  useNewQueueItemEditor,
  usePushQuestions,
  useQueueItemEditor,
} from 'pages/QueueItem/services/QueueItemEditorService';
import '../../App.css';
import { useQuery } from '@tanstack/react-query';
import { dataReviewApi } from 'api';
import {
  CreateQualityAssuranceLogRequest,
  MealPhotoQueueResponse,
  MealPushQuestionStatusEnum,
  PatientDietRestrictionResponse,
} from 'api/generated/MNT';
import { ReviewerAuthInfo } from 'apiClients/auth';
import { pluralize } from 'async-result/utils';
import { Capabilities, HasAuthFunc } from 'auth-capabilities';
import { MealNoteSelection, MealNoteView, useNlpPhraseParser } from 'components/MealNoteView';
import { MealQAForm } from 'components/MealQAForm';
import { MealQALogs } from 'components/MealQALogs';
import { PatientID } from 'components/PatientID';
import { QueuePriorityIcon } from 'components/QueueSummaryIcons';
import { useMealQALogs } from 'components/useMealQALogs';
import { logTrackedError } from 'errorTracking';
import { EDITOR_BUTTON_THEME } from 'food-editor/components/food-editor';
import { HumanTime, useInterval } from 'food-editor/components/HumanTime';
import { usePromptBeforePageUnload } from 'hooks/usePromptBeforePageUnload';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import _ from 'lodash';
import { HistoryView, MealChangeLogs, MealQoSDetails } from 'pages/LegacyReviewItem';
import { Form } from 'react-bootstrap';
import { useAsyncResult } from 'react-use-async-result';
import { useFoodResponses } from 'services/FoodDetailsService';
import { DraftItem, MealItemFoodMatchDetailsNLP } from 'types/DraftItem';
import { useEventListener } from 'usehooks-ts';
import { telemetrySend } from 'utils/telemetry';
import { FoodDrawerProvider, useFoodDrawerState } from './meal-builder/FoodDrawer';
import { MealNameAndTimeForQueue } from './meal-builder/MealNameAndTime';
import { useRelevantNutrients } from './services/QueueItemEditorService';

const MealConfirmNutrientsDialog = (props: {
  open: boolean,
  mixpanelProps: Record<string, any>,
  onClose: () => void,
  onSubmit: () => void,
}) => {
  const { draftItems, queueItem } = useQueueItemEditor();
  const patientContext = usePatientContext(queueItem);
  const mixpanelProps = {
    'Meal ID': queueItem.created_meal_id,
    'Queue ID': queueItem.id,
    ...(props.mixpanelProps || {}),
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        mixpanel.track('Meal nutrient confirmation: result', {
          ...mixpanelProps,
          Action: 'Cancel',
        });
        props.onClose();
      }}
      fullWidth
      disableScrollLock
      hideBackdrop={true}
      PaperProps={{
        elevation: 24,
        sx: {
          position: 'fixed',
          right: '5%',
          width: '40%',
          maxWidth: 800,
          height: '90%',
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h3">Verify Meal Nutrients</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please confirm the following information are correct. The patient will see them <b>immediately</b>{' '}
          in their app.
        </DialogContentText>
        <MealSummary
          queue={queueItem}
          patientContext={patientContext}
          draftItems={draftItems}
          isForConfirmationDialog
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {
            mixpanel.track('Meal nutrient confirmation: result', {
              ...mixpanelProps,
              Action: 'Modify',
            });
            props.onClose();
          }}
        >
          Modify Meal
        </Button>
        <Button
          onClick={() => {
            mixpanel.track('Meal nutrient confirmation: result', {
              ...mixpanelProps,
              Action: 'Confirm',
            });
            props.onSubmit();
          }}
        >
          Confirm and Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EscalationDetailsDialog = (props: {
  open: boolean,
  onClose: () => void,
  onSubmit: () => void,
}) => {
  const { authInfo } = useAuth();
  const { queueItem } = useQueueItemEditor();
  const [reason, setReason] = useState<string>('');
  const [otherExplanation, setOtherExplanation] = useState<string>('');

  const escalationOptions = [
    {
      value: 'unrecognized_meal_item',
      label: (
        <Typography>
          I <b>don't recognize</b> a meal item
        </Typography>
      ),
    },
    {
      value: 'too_complex',
      label: (
        <Typography>
          This is <b>too complex</b> for me
        </Typography>
      ),
    },
    {
      value: 'higher_priority',
      label: (
        <Typography>
          Working on a <b>higher priority queue</b>
        </Typography>
      ),
    },
    {
      value: 'other',
      label: (
        <Typography>
          Other
        </Typography>
      ),
    },
  ];

  const handleSubmit = async () => {
    if (!reason) {
      return;
    }
    const escalationReason = `${reason}${otherExplanation ? `: ${otherExplanation}` : ''}`;
    const res = await dataReviewApi.appApiDataReviewerEscalateMealPhotoQueueItem({
      meal_photo_queue_id: queueItem.id,
      reason: escalationReason,
    });
    mixpanel.track('Escalate meal', {
      'Meal ID': queueItem.created_meal_id,
      'Queue ID': queueItem.id,
      'Queue Level': queueItem.queue_metadata?.review_effective_level || 0,
      'Reviewer': authInfo?.reviewer_id,
      'Reviewer Level': authInfo?.review_max_queue_level || 0,
      'Reason': escalationReason,
    });
    props.onSubmit();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth
      disableScrollLock
      hideBackdrop={true}
      PaperProps={{
        elevation: 24,
        sx: {
          position: 'fixed',
          right: '5%',
          width: '40%',
          maxWidth: 800,
          height: '60%',
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h3">Escalation Details</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: 2 }}>
          Please provide details on why you're escalating this queue.
        </DialogContentText>
        <ThemeProvider theme={EDITOR_BUTTON_THEME}>
          <Grid
            container
            spacing={1}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            {escalationOptions.map((option) => (
              <Grid item key={option.value} xs={3}>
                <Button
                  variant="contained"
                  color={reason === option.value ? 'success' : 'primary'}
                  onClick={() => {
                    setReason(option.value);
                  }}
                  disableElevation
                  disableRipple
                >
                  {option.label}
                </Button>
              </Grid>
            ))}
            {reason === 'other' && (
              <TextField
                margin="dense"
                id="other_details"
                name="other_details"
                label="Escalation reason"
                type="text"
                fullWidth
                variant="standard"
                onChange={(e) => setOtherExplanation(e.target.value)}
              />
            )}
          </Grid>
        </ThemeProvider>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={!reason}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const QueueTimerChip = (props: { queueItem: MealQueueItem }) => {
  const { queueItem } = props;
  const [timeMinutes, setTimeMinutes] = useState(0);

  const refreshTime = () => {
    const time = queueItem.first_reviewer_access_time || queueItem.created_time;
    setTimeMinutes(moment().diff(moment(time), 'minutes'));
  };
  useEffect(refreshTime, [queueItem]);
  useInterval(1000, refreshTime);

  const color = timeMinutes > 6 ? 'error' : timeMinutes > 3 ? 'warning' : 'success';

  return <Chip variant="combined" color={color} label={pluralize(timeMinutes, 'minute') + ' old'} />;
};

type PostProcQuestionnaireQuestion = {
  id: string,
  label: string | JSX.Element,
  subQuestions?: Array<PostProcQuestionnaireQuestion>,
};

const PostProcessingQuestionnaire = (props: {
  header: JSX.Element,
  subheader: JSX.Element,
  questions: Array<PostProcQuestionnaireQuestion>,
  onValuesChange: (values: { [key: string]: any }) => void,
}) => {
  const [formValues, setFormValues] = useState<{ [key: string]: any }>({});

  const onQuestionnaireQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.checked,
    });
  };
  useEffect(() => {
    props.onValuesChange?.(formValues);
  }, [formValues]);

  let counter = 0;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="body1">
          {props.header}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={0.2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              {props.subheader}
            </Typography>
          </Grid>
          {props.questions.map((question, idx) => (
            <>
              <Grid
                item
                xs={12}
                key={question.id}
                style={{
                  paddingTop: 5,
                  marginLeft: -10,
                  paddingLeft: 10,
                  backgroundColor: ++counter % 2 == 0 ? '#f0f0f0' : undefined,
                }}
              >
                <Form.Group controlId={question.id}>
                  <Form.Check>
                    <Form.Check.Input
                      name={question.id}
                      onChange={onQuestionnaireQuestionChange}
                    />
                    <Form.Check.Label
                      style={{
                        maxWidth: idx == 0 ? 'calc(100% - 110px)' : '100%',
                      }}
                    >
                      {question.label}
                    </Form.Check.Label>
                  </Form.Check>
                </Form.Group>
              </Grid>
              {question.subQuestions?.map(subQuestion => (
                <Grid
                  item
                  xs={12}
                  key={question.id + ':' + subQuestion.id}
                  style={{
                    paddingTop: 5,
                    marginLeft: -10,
                    paddingLeft: 10,
                    backgroundColor: ++counter % 2 == 0 ? '#f0f0f0' : undefined,
                  }}
                >
                  <Form.Group controlId={question.id + ':' + subQuestion.id} style={{ marginLeft: 20 }}>
                    <Form.Check>
                      <Form.Check.Input
                        name={question.id + ':' + subQuestion.id}
                        onChange={onQuestionnaireQuestionChange}
                      />
                      <Form.Check.Label
                        style={{
                          maxWidth: idx == 0 ? 'calc(100% - 110px)' : '100%',
                        }}
                      >
                        {subQuestion.label}
                      </Form.Check.Label>
                    </Form.Check>
                  </Form.Group>
                </Grid>
              ))}
            </>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Form.Group controlId="other" style={{ marginTop: 10 }}>
          <Form.Control
            as="textarea"
            rows={3}
            onChange={e => setFormValues({ ...formValues, other: e.target.value })}
            placeholder="Any other comments to help Fany and the team improve the labeling process?"
          />
        </Form.Group>
      </Grid>
    </Grid>
  );
};

const PostProcessingAccessTimeQuestionnaire = (props: {
  accessTimeMinutes: number,
  onValuesChange: (values: { [key: string]: any }) => void,
}) => {
  const questions: Array<PostProcQuestionnaireQuestion> = [
    {
      id: 'processing_other_queue',
      label: (
        <span>
          I was <strong>processing another queue</strong>
        </span>
      ),
    },
    {
      id: 'no_assistance',
      label: (
        <span>
          I <strong>did not receive assistance</strong> from the team on shift because...
        </span>
      ),
      subQuestions: [
        {
          id: 'technical_issue',
          label: (
            <span>
              ... they had <strong>a technical issue</strong>
            </span>
          ),
        },
        {
          id: 'team_absent',
          label: (
            <span>
              ... they were <strong>absent</strong>
            </span>
          ),
        },
        {
          id: 'not_attentive',
          label: (
            <span>
              ... they were <strong>not attentive</strong>
            </span>
          ),
        },
      ],
    },

    {
      id: 'other_task',
      label: (
        <span>
          I was focused on a <strong>higher priority task</strong> (please mention in the notes)
        </span>
      ),
    },
    {
      id: 'afk',
      label: (
        <span>
          I was <strong>away from the computer</strong>, and...
        </span>
      ),
      subQuestions: [
        {
          id: 'asked_team_to_cover',
          label: (
            <span>
              ... had asked the <strong>team to cover for me</strong>
            </span>
          ),
        },
        {
          id: 'no_one_to_cover',
          label: (
            <span>
              ... <strong>nobody was available</strong> to cover for me
            </span>
          ),
        },
        {
          id: 'unable_to_ask_team',
          label: (
            <span>
              ... I was <strong>not able to ask the team</strong> to cover for me
            </span>
          ),
        },
      ],
    },
    {
      id: 'technical_issue',
      label: (
        <span>
          I experienced a <strong>technical issue</strong>
        </span>
      ),
      subQuestions: [
        {
          id: 'not_loading',
          label: (
            <span>
              ... because the <strong>page was not loading</strong>
            </span>
          ),
        },
      ],
    },
  ];

  return (
    <PostProcessingQuestionnaire
      onValuesChange={props.onValuesChange}
      header={
        <>
          This queue took more than {POST_PROC_QUESTIONNAIRE_ACCESS_TIME_MINUTES} minutes to{' '}
          <strong>access</strong>. To help Fany and the team understand how the labeling process can be improved, please
          answer the following questions.
        </>
      }
      subheader={
        <>
          This queue took <strong>{props.accessTimeMinutes.toFixed(1)}</strong> minutes to <strong>access</strong>{' '}
          because...
        </>
      }
      questions={questions}
    />
  );
};

const PostProcessingProcTimeQuestionnaire = (props: {
  procTimeMinutes: number,
  isPriorityPatient?: boolean,
  onValuesChange: (values: { [key: string]: any }) => void,
}) => {
  const questions: Array<PostProcQuestionnaireQuestion> = [
    {
      id: 'many_items',
      label: (
        <span>
          The meal contained <strong>many items</strong>
        </span>
      ),
    },
    {
      id: 'difficult_identification',
      label: (
        <span>
          Some item(s) were <strong>difficult to identify</strong>
        </span>
      ),
      subQuestions: [
        {
          id: 'bad_photo',
          label: (
            <span>
              ... because the <strong>photo is bad</strong> (ex, blurry, too dark, etc)
            </span>
          ),
        },
        {
          id: 'unfamiliar_foods',
          label: (
            <span>
              ... because the meal contains <strong>unfamiliar food(s)</strong> (ie, food you have never seen before)
            </span>
          ),
        },
        {
          id: 'ambiguous_foods',
          label: (
            <span>
              ... because some <strong>food(s) are ambiguous</strong> (ex, pork and chicken can look very similar)
            </span>
          ),
        },
      ],
    },
    {
      id: 'difficult_sizing',
      label: (
        <span>
          Some item(s) were <strong>difficult to size</strong>
        </span>
      ),
    },
    {
      id: 'research_required',
      label: (
        <span>
          <strong>Research was required</strong> to identify or size some item(s)
        </span>
      ),
    },
    {
      id: 'interrupted',
      label: (
        <span>
          I was <strong>interrupted</strong> with a higher priority task
        </span>
      ),
    },
    {
      id: 'training_approval',
      label: (
        <span>
          I am <strong>in training</strong> and needed to wait for approval
        </span>
      ),
    },
    {
      id: 'technical_issue',
      label: (
        <span>
          I experienced a <strong>technical issue</strong> (please make a note below)
        </span>
      ),
    },
  ];

  return (
    <PostProcessingQuestionnaire
      onValuesChange={props.onValuesChange}
      header={
        <>
          This queue took more than {props.isPriorityPatient
            ? POST_PROC_QUESTIONNAIRE_PROC_TIME_MINUTES_PU + (' (priority user)')
            : POST_PROC_QUESTIONNAIRE_PROC_TIME_MINUTES} minutes to{' '}
          <strong>process</strong>. To help Fany and the team understand how the labeling process can be improved,
          please answer the following questions.
        </>
      }
      subheader={
        <>
          This queue took <strong>{props.procTimeMinutes.toFixed(1)}</strong> minutes to <strong>process</strong>{' '}
          because...
        </>
      }
      questions={questions}
    />
  );
};

const PostProcessingQuestionnairesModal = (props: {
  queueItem: MealQueueItem,
  queueSubmitResponse: SubmitMealResult | null,
  onSubmit: () => void,
}) => {
  const { authInfo } = useAuth();
  const { draftItems } = useQueueItemEditor();
  const telemetryRef = React.useRef({
    windowFocusCount: 0,
    mouseDownCount: 0,
    keyDownCount: 0,
    requestHelpCount: 0,
    requestHelpMessages: [] as string[],
  });
  useEventListener('mousedown', () => {
    telemetryRef.current.mouseDownCount += 1;
  });
  useEventListener('keydown', () => {
    telemetryRef.current.keyDownCount += 1;
  });
  useEventListener('focus', () => {
    telemetryRef.current.windowFocusCount += 1;
  });

  useEffect(() => {
    onSendHelpCallback.callback = (message) => {
      telemetryRef.current.requestHelpCount += 1;
      telemetryRef.current.requestHelpMessages.push(message);
    };
    return () => {
      onSendHelpCallback.callback = null;
    };
  }, []);

  const [formStack, setFormStack] = useState([] as ('AccessTime' | 'ProcTime')[]);
  useEffect(() => {
    const newFormStack = [] as typeof formStack;
    if ((props.queueSubmitResponse?.accessTime ?? 0) > POST_PROC_QUESTIONNAIRE_ACCESS_TIME_MINUTES) {
      newFormStack.push('AccessTime');
    }

    if (
      (props.queueSubmitResponse?.processingTime ?? 0) > POST_PROC_QUESTIONNAIRE_PROC_TIME_MINUTES
      || (props.queueItem.is_priority_patient
        && (props.queueSubmitResponse?.processingTime ?? 0) > POST_PROC_QUESTIONNAIRE_PROC_TIME_MINUTES_PU)
    ) {
      newFormStack.push('ProcTime');
    }
    setFormStack(newFormStack);
  }, [props.queueSubmitResponse]);
  const modalVisible = formStack.length > 0;

  const [formValues, setFormValues] = useState<{ [key: string]: any }>({});
  const startTimeRef = React.useRef(0);
  useEffect(() => {
    if (!modalVisible) {
      return;
    }
    startTimeRef.current = Date.now();
  }, [modalVisible]);

  const submitRes = useAsyncResult();

  const onSubmit = () => {
    const curForm = formStack[0];
    if (!curForm) {
      props.onSubmit();
      return;
    }

    const submitValue = {
      queueId: props.queueItem.id,
      formDurationSeconds: (Date.now() - startTimeRef.current) / 1000,
      mealItemCount: draftItems.length,
      addonCount: _.sum(draftItems.map(i => i.item.custom_addons?.length || 0)),
      customItemCount: draftItems.filter(i => i.item.custom_item).length,
      ...telemetryRef.current,
      form: formValues,
    };
    let telemetryValue: number = 0;
    if (curForm == 'AccessTime') {
      Object.assign(submitValue, {
        accessTimeThresholdSeconds: POST_PROC_QUESTIONNAIRE_PROC_TIME_MINUTES * 60,
        accessTimeSeconds: (props?.queueSubmitResponse?.accessTime ?? 0) * 60,
      });
      telemetryValue = (props?.queueSubmitResponse?.accessTime ?? 0) * 60;
    } else if (curForm == 'ProcTime') {
      Object.assign(submitValue, {
        // Note: maintain this "type" for backwards compatibility, but new code
        // should not use it.
        type: 'post_proc_questionnaire',
        procTimeThresholdSeconds: (props.queueItem.is_priority_patient
          ? POST_PROC_QUESTIONNAIRE_PROC_TIME_MINUTES_PU
          : POST_PROC_QUESTIONNAIRE_PROC_TIME_MINUTES) * 60,
        procTimeSeconds: (props?.queueSubmitResponse?.processingTime ?? 0) * 60,
      });
      telemetryValue = (props?.queueSubmitResponse?.processingTime ?? 0) * 60;
    }
    const res = telemetrySend({
      name: `QueuePostProcQuestionnaire:${curForm}`,
      key: `${props.queueItem.id}:${authInfo?.reviewer_id}`,
      value: telemetryValue,
      properties: submitValue,
    });

    submitRes.bind(res);
    res.then(() => {
      if (formStack.length == 1) {
        props.onSubmit();
        return;
      }
      setFormStack(formStack.slice(1));
      setFormValues({});
    });
  };

  const curForm = formStack[0];

  if (!curForm) {
    return null;
  }

  return (
    <Dialog open={modalVisible} maxWidth="sm">
      <DialogTitle>
        <strong>{curForm == 'AccessTime' ? 'Access' : 'Processing'}</strong> Time Questionnaire
      </DialogTitle>

      <DialogContent>
        {curForm == 'AccessTime' && (
          <PostProcessingAccessTimeQuestionnaire
            accessTimeMinutes={props.queueSubmitResponse?.processingTime ?? 0}
            onValuesChange={setFormValues}
          />
        )}
        {curForm == 'ProcTime' && (
          <PostProcessingProcTimeQuestionnaire
            procTimeMinutes={props.queueSubmitResponse?.processingTime ?? 0}
            onValuesChange={setFormValues}
            isPriorityPatient={props.queueItem.is_priority_patient}
          />
        )}

        {submitRes.isError && (
          <Alert severity="error">
            Submit error: {'' + submitRes.error}
          </Alert>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={onSubmit} disabled={submitRes.isPending}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

const QueueItemEditActionsBar = (props: {
  onEditCallback?: () => void,
  onEscalate: () => void,
}) => {
  const editor = useQueueItemEditor();
  const foodDrawer = useFoodDrawerState();
  const { authInfo } = useAuth();
  const [escalateDetailsIsOpen, setEscalateDetailsIsOpen] = useState<boolean>(false);

  const handleHelpRequest = (queueItem: MealQueueItem) => {
    mixpanel.track('clicked requestAssistance:open');
    if (queueItem && authInfo) {
      const helpMessage = window.prompt('Enter help message:');
      console.log(helpMessage);
      if (helpMessage) {
        sendHelpRequest(
          queueItem.data_reviewer_group_id,
          authInfo.reviewer_id,
          queueItem.id,
          authInfo.access_token,
          helpMessage,
        );
        mixpanel.track('clicked requestAssistance:sent');
      }
    }
  };

  return (
    <Box sx={{ mt: 1.5, display: 'flex', justifyContent: 'flex-end' }}>
      {editor.selectedItem
        ? <Button variant="outlined" onClick={() => handleHelpRequest(editor.queueItem)}>Request assistance</Button>
        : (
          <MealBuilderActions
            onCreateNew={() => {
              editor.selectedItemAddNewItem({
                id: null,
                item: getEmptyMealItem(),
                searchItem: null,
                queryText: null,
                foodMatchDetails: null,
              });
              foodDrawer.show({
                initialSearchText: '',
              });
              if (props.onEditCallback) {
                props.onEditCallback();
              }
            }}
            onRequestAssistance={() => handleHelpRequest(editor.queueItem)}
            onEscalate={() => setEscalateDetailsIsOpen(true)}
            onPaste={() => { /* XXX */ }}
            queueItem={editor.queueItem}
            copiedItem={editor.copiedItem}
            mealDeleted={editor.mealDeleted}
          />
        )}
      <EscalationDetailsDialog
        open={!!escalateDetailsIsOpen}
        onClose={() => setEscalateDetailsIsOpen(false)}
        onSubmit={props.onEscalate}
      />
    </Box>
  );
};

const QueueItemInfoHeader = (props: {
  hasAuth: HasAuthFunc,
  queueItem: MealPhotoQueueResponse,
  authInfo: ReviewerAuthInfo | null,
  patientContext: PatientContext,
  dietRestrictions: PatientDietRestrictionResponse[],
  handleMealNoteSelect: (item: MealNoteSelection) => void,
  draftItems: DraftItem[],
  showEditActions: boolean,
  onEscalate: () => void,
  onEditCallback: () => void,
}) => {
  const { queueItem, authInfo } = props;
  const { mealDeleted } = useQueueItemEditor();
  return (
    <MainCard
      style={{
        position: 'sticky',
        top: 60,
        zIndex: 10,
      }}
    >
      <Stack direction="row" spacing={1} style={{ float: 'right' }}>
        {!queueItem.is_processed && <QueueTimerChip queueItem={queueItem} />}
        {mealDeleted && <Chip variant="combined" color="error" label="Meal Deleted" />}
        {queueItem.is_processed
          ? (
            <Chip
              variant="combined"
              color="warning"
              label={'QA1: ' + queueItem.first_reviewer_user_id}
            />
          )
          : authInfo?.reviewer_id === queueItem.first_reviewer_user_id
          ? <Chip variant="combined" color="info" label="QA1: you" />
          : queueItem.first_reviewer_user_id
          ? (
            <Chip
              variant="combined"
              color="error"
              label={'QA1: ' + queueItem.first_reviewer_user_id}
            />
          )
          : ''}
        <QueuePriorityIcon queue={queueItem} size="medium" />
      </Stack>
      <PatientDiet dietRestrictions={props.dietRestrictions} />
      {queueItem.preparation_method && (
        <Typography variant="body1">
          <b>Preparation:{' '}</b>
          {queueItem.preparation_method == 'homemade' && (
            <>
              <CottageIcon fontSize="small" sx={{ fontSize: '90%' }} />
              {' '}
            </>
          )}
          {queueItem.preparation_method}
        </Typography>
      )}

      <MealNameAndTimeForQueue queueItem={queueItem} />

      <Typography variant="h4">
        <b>Meal note:</b> {queueItem.patient_note
          ? (
            <MealNoteView
              value={queueItem.patient_note}
              onClick={props.handleMealNoteSelect}
              mealItems={props.draftItems}
              queueId={queueItem.id}
            />
          )
          : <span className="text-secondary">no patient note provided</span>}
      </Typography>
      {!!queueItem.patient_note_translations && !!('en' in queueItem.patient_note_translations) && (
        <Typography variant="h4">
          <b>Translated meal note:</b>{' '}
          <MealNoteView
            value={queueItem.patient_note_translations.en as string}
            onClick={props.handleMealNoteSelect}
            mealItems={props.draftItems}
            queueId={queueItem.id}
          />
        </Typography>
      )}
      {props.showEditActions && (
        <QueueItemEditActionsBar onEditCallback={props.onEditCallback} onEscalate={props.onEscalate} />
      )}
    </MainCard>
  );
};

const QueueItemHeaderValue = (
  props: React.PropsWithChildren<{
    label: string,
  }>,
) => (
  <tr>
    <td style={{ textAlign: 'right', paddingRight: 3 }}>
      <Typography sx={{ fontWeight: 'bold' }}>{props.label}:</Typography>
    </td>
    <td>
      <Typography>{props.children}</Typography>
    </td>
  </tr>
);

const POST_PROC_QUESTIONNAIRE_PROC_TIME_MINUTES = 5;
const POST_PROC_QUESTIONNAIRE_PROC_TIME_MINUTES_PU = 4;
const POST_PROC_QUESTIONNAIRE_ACCESS_TIME_MINUTES = 3;

const QueueItemView = (props: {
  onQueueComplete?: () => void,
}) => {
  const { authInfo, hasAuth } = useAuth();
  const {
    queueItem,
    draftItems,
    updateDraftItem,
    selectedItem,
    saveChanges,
    submitMeal,
    claimQueue,
    claimQueueRes,
    handleMatchItemSelect,
    mealDeleted,
  } = useQueueItemEditor();
  const pushQuestionService = usePushQuestions({
    patientId: queueItem.patient_id,
    mealId: queueItem.created_meal_id,
  });
  const navigate = useNavigate();
  const patientContext = usePatientContext(queueItem);
  const [changesSaved, setChangesSaved] = useState(true);
  const { qaLogs, postMealQA } = useMealQALogs(queueItem);
  usePromptBeforePageUnload({ show: !changesSaved });
  const [queueSubmitResponse, setQueueSubmitResponse] = useState(null as null | SubmitMealResult);
  const parsedMealNote = useNlpPhraseParser(queueItem.patient_note, queueItem.id);
  const [isConfirmingNutrients, setIsConfirmingNutrients] = useState(null as Record<string, any> | null);
  const mealNutrientValues = useMealNutrientValues({ draftItems });

  const handleMealNoteSelect = (item: MealNoteSelection) => {
    if (!item) {
      return;
    }

    handleMatchItemSelect({
      matchText: item.food_name,
      mealItem: item.match.meal_item,
      relatedFoodResponse: item.relatedFoodResponse,
      mixpanelSource: 'Meal notes',
      foodMatchDetails: {
        user_type: 'internal',
        source_name: 'nlp_note',
        source_details: {
          nlp_selection: item.match,
        },
      } satisfies MealItemFoodMatchDetailsNLP,
    });
  };

  // removeDraftItem() is already called in QueueMealItems component

  const handleQASubmit = async (data: CreateQualityAssuranceLogRequest) => {
    return postMealQA(authInfo?.access_token || '', {
      data_reviewer_id: authInfo?.reviewer_id || 0,
      meal_photo_queue_id: queueItem.id,
      ...data,
    });
  };

  const relevantNutrients = useRelevantNutrients({
    context: 'item',
    patientContext: patientContext,
  });

  const saveRes = useAsyncResult<void>();

  const missingSearchItems = useFoodResponses(draftItems.filter(i => !i.searchItem).map(i => i.item.food_name));
  const allSearchItemQueryDone = Object.values(missingSearchItems).every(i => i.query.isSuccess);

  useEffect(() => {
    if (!allSearchItemQueryDone) {
      return;
    }
    draftItems.forEach((draftItem) => {
      if (queueItem.is_processed && draftItem?.item.food_name && !draftItem.searchItem) {
        updateDraftItem(draftItem, { searchItem: missingSearchItems[draftItem.item.food_name].query.data });
      }
    });
  }, [allSearchItemQueryDone]);

  const validateServingUnits = () => {
    for (const draftItem of draftItems) {
      if (!draftItem.searchItem) {
        continue;
      }

      const servingUnit = draftItem.searchItem?.serving_units
        ? draftItem.searchItem?.serving_units.filter(su =>
          su.label == draftItem.item.serving_unit_label && su.amount == draftItem.item.serving_unit_amount
        )
        : [];

      if (servingUnit.length == 0) {
        return false;
      }
    }

    return true;
  };

  const handleSubmitClick = () => {
    const outOfThresholdNutrients: { [key: string]: number } = {};
    Object.entries(NUTRIENT_WARNING_THRESHOLDS).forEach(([nutrient, threshold]) => {
      const value = nutrient == 'weight_g'
        ? getMealTotalWeight(draftItems)
        : mealNutrientValues.getMealTotal(nutrient as any);
      if (value && value > threshold) {
        outOfThresholdNutrients[nutrient] = value;
      }
    });

    draftItems.forEach(item => {
      const itemWeight = getItemWeightWithoutAddons(item.item);
      if (itemWeight == NUTRIENT_WARNING_WEIGHT_1G) {
        outOfThresholdNutrients['weight_g'] = itemWeight;
      }
    });

    const hasNutrientWarnings = Object.keys(outOfThresholdNutrients).length > 0;

    const hasPendingSystemQuestions = pushQuestionService.mealQuestions.some(q => {
      if (q.question_status != MealPushQuestionStatusEnum.Pendingsystem) {
        return false;
      }
      const draftItem = draftItems.find(i => i.item.id == q.meal_item_id);
      if (!draftItem) {
        return false;
      }
      return (
        !draftItem.pushQuestionUpdate
        || draftItem.pushQuestionUpdate.question_status == MealPushQuestionStatusEnum.Pendingsystem
      );
    });

    if (hasPendingSystemQuestions) {
      const res = window.prompt(
        'This meal has pending questions. Please either:\n'
          + '- Resolve them by clicking the red icon then "resolve", or\n'
          + '- Enter "ignore" to submit anyway.',
      );
      if (res != 'ignore') {
        return;
      }
    }

    const shouldConfirm = (!isProcessed && relevantNutrients.length > 0)
      || hasNutrientWarnings;

    if (!shouldConfirm) {
      saveRes.bind(processSubmit());
      return;
    }

    mixpanel.track('Meal nutrient confirmation: displayed');
    setIsConfirmingNutrients({
      Reason: hasNutrientWarnings ? 'Nutrient threshold warnings' : 'User real-time nutrients',
      ...outOfThresholdNutrients,
    });
  };

  const processSubmit = async () => {
    setQueueSubmitResponse(null);
    setIsConfirmingNutrients(null);

    if (queueItem.is_processed) {
      if (!validateServingUnits()) {
        window.alert('Fix serving units before saving');
        return;
      }

      try {
        await saveChanges();
      } catch (err) {
        logTrackedError({
          sourceName: `QueueItemView.handleSubmitClick`,
          origin: err as any,
          stackError: new Error(),
          context: { queueId: queueItem.id },
          userMessage: 'Unexpected error saving changes.',
        });
        return;
      }
      setChangesSaved(true);
      window.alert('Changes Saved!');
      return;
    }

    const SUSPICIOUS_WEIGHT_THRESHOLD = 750;

    const suspiciousWeightItems = draftItems.filter(i => (
      i.item.serving_unit_amount * i.item.servings > SUSPICIOUS_WEIGHT_THRESHOLD
    ));

    if (suspiciousWeightItems.length > 0) {
      if (
        !window.confirm(
          `The following meal items are over ${SUSPICIOUS_WEIGHT_THRESHOLD}g.\n`
            + suspiciousWeightItems
              .map(item =>
                `- ${item.item.food_name} (${formatNumber(item.item.serving_unit_amount * item.item.servings)}g)`
              )
              .join('\n')
            + `\nAre you sure you want to submit?\n`,
        )
      ) {
        return;
      }
    }

    if (queueItem.queue_type == 'custom') {
      if (!window.confirm('This meal was manually created by the patient, are you sure you want to submit?')) {
        return;
      }
    }

    let totalItemCount = 0;
    draftItems.forEach(item => {
      totalItemCount += 1;
      totalItemCount += item.item.custom_addons?.length || 0;
    });
    if (totalItemCount < parsedMealNote.result.length) {
      const res = window.prompt(
        `WARNING: more items in meal note than meal\n`
          + `Meal note foods: ${parsedMealNote.result.length}\n`
          + `Items + addons: ${totalItemCount}\n`
          + `\n`
          + `Type 'yes' to submit anyway.`,
      );
      mixpanel.track('Suspicious queue submit', {
        Reason: 'Fewer items than NLP',
        'NLP item count': parsedMealNote.result.length,
        'Item and addon count': totalItemCount,
        'Response': res,
      });
      if (res != 'yes') {
        return;
      }
    }

    if (draftItems.length == 0) {
      const res = window.prompt('Meal is empty. Enter "empty" to submit anyway.');
      if (res != 'empty') {
        return;
      }
    } else if (!window.confirm('Confirm Submission')) {
      return;
    }

    const submitResponse = await submitMeal();
    setQueueSubmitResponse(submitResponse);

    mixpanel.track('clicked submit', { success: submitResponse.success });
    if (!submitResponse.success) {
      return;
    }

    if (submitResponse.processingTime && !queueItem.is_processed) {
      alert(
        'Submission successful. Access time: ' + submitResponse.accessTime?.toFixed(2) + 'min; Processing time: '
          + submitResponse.processingTime.toFixed(2) + 'min',
      );

      if (
        (submitResponse.processingTime > POST_PROC_QUESTIONNAIRE_PROC_TIME_MINUTES)
        || ((submitResponse.accessTime ?? 0) > POST_PROC_QUESTIONNAIRE_ACCESS_TIME_MINUTES)
        || (queueItem.is_priority_patient
          && submitResponse.processingTime > POST_PROC_QUESTIONNAIRE_PROC_TIME_MINUTES_PU)
      ) {
        return;
      }
    }
    onQueueComplete();
  };

  const onQueueComplete = () => {
    props.onQueueComplete?.();
  };

  const isEditing = !!selectedItem;
  const clinicType = patientContext.context?.clinics?.[0]?.clinic_type;
  const country = patientContext.context?.country;
  const isProcessed = queueItem.is_processed;

  const isInvalid = React.useMemo(() => {
    return draftItems.some(i => {
      return draftItemGetWarnings({
        draftItem: i,
        relevantNutrients,
      })?.isFatal;
    });
  }, [draftItems, relevantNutrients]);

  const [overrideClaimQueue, setOverrideClaimQueue] = useState(false);
  const shouldShowClaimQueue = overrideClaimQueue ? false : (
    !isProcessed
    && queueItem.first_reviewer_user_id != authInfo?.reviewer_id
    && hasAuth(Capabilities.mpqItemLabel)
  );
  const shouldShowQueueProcessing = isProcessed ? false : !hasAuth(Capabilities.mpqItemEdit);

  const dietRestrictions = queueItem.queue_metadata?.patient_context?.diet_restrictions
    ?? patientContext.context?.diet_restrictions
    ?? [];

  const canGoBack = window.history.length > 1;
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Grid container spacing={3}>
      <PostProcessingQuestionnairesModal
        queueItem={queueItem}
        queueSubmitResponse={queueSubmitResponse}
        onSubmit={onQueueComplete}
      />

      <Grid item xs={12}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Stack direction="row" alignItems="center" spacing={1.5}>
              {canGoBack && (
                <IconButton variant="outlined" color="primary" onClick={handleGoBack}>
                  <LeftOutlined />
                </IconButton>
              )}
              <Typography variant="h2">
                Queue #{queueItem.id}{' '}
                <span style={{ opacity: isProcessed ? 0.7 : undefined }}>
                  ({mealDeleted ? 'deleted' : isProcessed ? 'processed' : 'new'})
                </span>
              </Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Stack direction="row" spacing={2}>
              <table style={{ marginRight: 5 }}>
                <QueueItemHeaderValue label="Patient ID">
                  <PatientID userId={queueItem.patient_id} isPriority={!!queueItem.is_priority_patient} />
                </QueueItemHeaderValue>
                <QueueItemHeaderValue label="Patient Age">
                  <PatientAgeValue patientContext={patientContext} />
                </QueueItemHeaderValue>
              </table>
              <table style={{ marginRight: 5 }}>
                <QueueItemHeaderValue label="Meal ID">
                  {queueItem.created_meal_id}
                </QueueItemHeaderValue>
                <QueueItemHeaderValue label="Country">
                  {patientContext.query.isLoading
                    ? <span style={{ fontStyle: 'italic' }}>Loading…</span>
                    : country ?? 'unknown'}
                </QueueItemHeaderValue>
              </table>
              <table style={{ marginRight: 5 }}>
                <QueueItemHeaderValue label="Created">
                  <HumanTime value={queueItem.created_time} />
                </QueueItemHeaderValue>
                <QueueItemHeaderValue label="Clinic Type">
                  {patientContext.query.isLoading
                    ? <span style={{ fontStyle: 'italic' }}>Loading…</span>
                    : clinicType}
                </QueueItemHeaderValue>
              </table>
            </Stack>
          </Grid>
          {
            /* <Grid item>
            <Stack direction="row" alignItems="center" spacing={1} sx={{ maxWidth: 600 }}>
              <ShowPatientNote queueItem={queueItem} />
            </Stack>
          </Grid> */
          }
        </Grid>
      </Grid>
      <Grid container item xs={12} rowGap={2}>
        <Grid container spacing={1}>
          <Grid item xs={5.5}>
            <Stack spacing={1} style={{ position: 'sticky', top: '4.5rem' }}>
              <MealPhoto queueItem={queueItem} />
              <MealSummary
                queue={queueItem}
                patientContext={patientContext}
                draftItems={draftItems}
              />
            </Stack>
          </Grid>
          <Grid item xs={6.5}>
            <Stack spacing={1}>
              <QueueItemInfoHeader
                hasAuth={hasAuth}
                queueItem={queueItem}
                authInfo={authInfo}
                patientContext={patientContext}
                dietRestrictions={dietRestrictions}
                handleMealNoteSelect={handleMealNoteSelect}
                draftItems={draftItems}
                showEditActions={!(shouldShowClaimQueue || shouldShowQueueProcessing)}
                onEscalate={() => props.onQueueComplete?.()}
                onEditCallback={() => isProcessed && setChangesSaved(false)}
              />
              {shouldShowClaimQueue
                ? (
                  <MainCard>
                    {!queueItem.first_reviewer_user_id
                      ? (
                        <Stack spacing={2}>
                          <Typography variant="h2">Queue Unclaimed</Typography>
                          <Typography>
                            This queue has not been claimed yet. Would you like to claim it to start labeling?
                          </Typography>
                          {claimQueueRes.isError && (
                            <Alert severity="error">
                              {'' + claimQueueRes.error}
                            </Alert>
                          )}
                          <Button
                            variant="contained"
                            color={claimQueueRes.isError ? 'warning' : 'primary'}
                            size="large"
                            fullWidth
                            disabled={claimQueueRes.isPending}
                            onClick={() => {
                              if (claimQueueRes.isError) {
                                setOverrideClaimQueue(true);
                                return;
                              }
                              claimQueue();
                            }}
                          >
                            {claimQueueRes.isError ? 'Edit queue anyway' : 'Claim queue and start labeling'}
                          </Button>
                        </Stack>
                      )
                      : (
                        <Stack spacing={2}>
                          <Typography variant="h2">Queue Claimed by {queueItem.first_reviewer_user_id}</Typography>
                          <Typography>
                            This queue has been claimed{' '}
                            <em>
                              <HumanTime value={queueItem.first_reviewer_access_time} />
                            </em>{' '}
                            by <em>{queueItem.first_reviewer_user_id}</em>.
                          </Typography>
                          <Button
                            variant="contained"
                            color="warning"
                            size="large"
                            fullWidth
                            onClick={() => setOverrideClaimQueue(true)}
                          >
                            Edit queue anyway
                          </Button>
                        </Stack>
                      )}
                  </MainCard>
                )
                : shouldShowQueueProcessing
                ? (
                  <MainCard>
                    <Stack spacing={2}>
                      <Typography variant="h2">Queue Processing</Typography>
                      <Typography>
                        This queue is currently being processed. Please check again later.
                      </Typography>
                    </Stack>
                  </MainCard>
                )
                : (
                  <>
                    {queueItem.is_processed && <HistoryView queueItem={queueItem} draftItems={draftItems} asCard />}
                    <QueueMealItems
                      onEscalate={() => props.onQueueComplete?.()}
                      onEditCallback={() => isProcessed && setChangesSaved(false)}
                      onDeleteCallback={(item: DraftItem) => isProcessed && setChangesSaved(false)}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      disabled={isEditing || saveRes.isPending || isInvalid || mealDeleted}
                      onClick={handleSubmitClick}
                    >
                      {isProcessed ? 'Save Edits' : 'Submit'}
                    </Button>
                    <MealConfirmNutrientsDialog
                      open={!!isConfirmingNutrients}
                      mixpanelProps={isConfirmingNutrients as Record<string, any>}
                      onClose={() => setIsConfirmingNutrients(null)}
                      onSubmit={() => saveRes.bind(processSubmit())}
                    />
                    {queueItem.is_processed && (
                      <MealChangeLogs
                        queueItem={queueItem}
                        draftItems={draftItems}
                      />
                    )}
                  </>
                )}
            </Stack>
          </Grid>
        </Grid>
        {queueItem.is_processed && (hasAuth(Capabilities.mpqQAView) || hasAuth(Capabilities.patientReportView)) && (
          <Grid container spacing={1}>
            <Grid item xs={5.5}>
              {!mealDeleted && <MealQAForm item={queueItem} qaLogs={qaLogs} postMealQA={handleQASubmit} />}
              <div style={{ margin: 20 }}>
                <Button variant="outlined" color="primary" onClick={handleGoBack}>
                  <LeftOutlined /> Back
                </Button>
              </div>
            </Grid>
            <Grid item xs={6.5}>
              <Stack spacing={1}>
                <MealQALogs qaLogs={qaLogs} />
                <MealQoSDetails item={queueItem} />
              </Stack>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export const MealOrQueueItemPage = () => {
  const { itemId, mealId } = useParams();
  const isMeal = !!mealId;

  const queueIdQuery = useQuery(['queue-id-for-meal', mealId], async () => {
    if (!mealId) {
      return '';
    }

    // Returns 404 for meals without an associated photo queue
    const response = await dataReviewApi.appApiDataReviewerGetMealPhotoQueueId({
      meal_id: +mealId,
    }).then(res => res.data);

    return response.queueId;
  }, { enabled: isMeal });

  return (
    <>
      {isMeal && queueIdQuery.isError && <div>Error: {'' + queueIdQuery.error}</div>}
      {isMeal && queueIdQuery.isLoading && <div>Loading...</div>}
      {isMeal && queueIdQuery.isSuccess && <QueueItemPage itemId={queueIdQuery.data} />}
      {!isMeal && <QueueItemPage itemId={itemId} />}
    </>
  );
};

export const QueueItemEditorProvider = (props: {
  queueItemId: string | undefined,
  children: React.ReactNode,
}) => {
  const pageState = useNewQueueItemEditor({
    queueItemId: props.queueItemId,
  });

  return (
    <QueueItemEditorStateCtx.Provider value={pageState}>
      <FoodDrawerProvider>
        {props.children}
      </FoodDrawerProvider>
    </QueueItemEditorStateCtx.Provider>
  );
};

export const QueueItemPage = (props: { itemId: string | undefined }) => {
  const { itemId } = props;
  useSetPageTitle('queue #', itemId);

  return (
    <QueueItemEditorProvider queueItemId={itemId}>
      <QueueItemPageInner />
    </QueueItemEditorProvider>
  );
};

export const QueueItemPageInner = () => {
  const navigate = useNavigate();
  const features = useFeatures();
  const qpState = useQueueItemEditor();
  const { queueItem } = qpState;

  if (qpState.query.isError) {
    return <div>Load error: {'' + qpState.query.error}</div>;
  }

  if (!queueItem || !qpState.query.isSuccess) {
    return <div>Loading...</div>;
  }

  const canGoBack = window.history.length > 1;

  const handleGoBack = () => {
    if (canGoBack) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  const handleQueueComplete = () => {
    navigate('/');
  };

  return <QueueItemView onQueueComplete={handleQueueComplete} />;
};
